import React from 'react';
import { Box, Heading, Text, Container, UnorderedList, ListItem } from '@chakra-ui/react';

const Term = () => {
  return (
    <Box bg="#f9f9f9" minHeight="100vh" py={10}>
      <Container maxW="container.md" bg="white" boxShadow="md" borderRadius="md" p={8}>
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} color="#000D6B" mb={4}>
          Terms & Conditions
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        Welcome to our website. These Terms and Conditions, along with any documents they reference, govern your use of our website, along with its associated sites, services, applications, and tools. By accessing or using our services, you agree to comply with and be bound by these Terms and Conditions. The website and its services are managed by Goinfinite Digital Services Pvt Ltd, which operates under the brand name Car Expert.
        </Text>

        {/* <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          The Website is operated by www.carexpert.org.in ("We"). Please visit the Contact Us page on this website for our contact details.
        </Text> */}

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          1. Acceptance Of Terms*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        By accessing or using our website and services, you confirm that you accept these Terms and Conditions in full. If you do not agree to these terms, please do not use our website or services.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
           2. Company Information*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        The website, www.carexpert.org.in, is operated by Goinfinite Digital Services Pvt Ltd. For any inquiries or further information, please visit the "Contact Us" page on our website.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          3. Changes to Terms*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        We may update these Terms and Conditions from time to time to reflect changes in our services or to comply with legal requirements. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of the revised terms.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          4. Use of Services*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        You agree to use our website and services only for lawful purposes and in accordance with these Terms and Conditions. You must not use our website in a way that may harm or disrupt its functionality, or in a manner that breaches any applicable laws or regulations.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          5. Intellectual Property*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        All content and materials available on our website, including but not limited to text, graphics, logos, and images, are the property of Goinfinite Digital Services Pvt Ltd or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or otherwise use any content from our website without prior written permission.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          6.  User Obligations*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        You agree to provide accurate, current, and complete information as required for using our services. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          7. Limitation of Liability*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        To the extent permitted by law, Goinfinite Digital Services Pvt Ltd will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our website or services. This includes, but is not limited to, any loss of data, loss of business, or financial losses.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          8. Links to Other Websites*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        Our website may contain links to third-party websites. We are not responsible for the content or practices of these external sites. Your use of such websites is at your own risk and subject to their respective terms and conditions.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          9. Termination*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        We reserve the right to suspend or terminate your access to our website and services at our sole discretion, without notice, for any reason, including if you violate these Terms and Conditions.
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          10. Governing Law*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        These Terms and Conditions are governed by and construed in accordance with the laws of Uttar Pradesh. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Gautam Budh Nagar[Uttar Pradesh].
        </Text>

        <Heading as="h2" fontSize={{ base: "md", md: "xl" }} color="#000D6B" mb={4} mt={8}>
        11 . Contact Information*
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
        For any questions or concerns regarding these Terms and Conditions, please visit the "Contact Us" page on our website for our contact details.

        By using our website, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
        </Text>

        {/* <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          On receipt of such intimation and Welcome Pack, Car Expert shall verify the details of your membership and the request for cancellation placed by you. On Car Expert's satisfaction that your request is in accordance with this agreement, Car Expert shall carry out the following (collectively, “Cancellation”): (I) Discontinue the membership issued to you and issue a refund of the amount paid by you as Membership Fees. On Cancellation as aforesaid, Car Expert shall intimate you of the fact of such Cancellation and shall inform you of the method of refund of your Membership Fees.
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Such Cancellation shall be subject to the following terms:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>
              Cancellations shall be considered only if (i) a valid request is made in this regard within 14 (fourteen) days of receipt of the membership, and (ii) the entire Welcome Pack is returned to Car Expert within 14 (fourteen) days of receipt of the cancellation request as aforesaid.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have purchased the Membership under any interest-free easy monthly installment (EMI) option offered by Car Expert.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have in any way availed any of the benefits available under the membership prior to confirmation of cancellation by Car Expert.
            </ListItem>
            <ListItem>
              In the event a request for cancellation is received by Car Expert after dispatch of the Membership's Welcome Pack to you, Car Expert shall deduct an amount of Rs. 1,000 (Rupees one thousand only) or 10% of the membership fee, whichever is higher, from your membership fees and only the balance shall be refunded to you as part of the cancellation.
            </ListItem>
            <ListItem>
              In the event a request for cancellation is received by Car Expert after a positive welcome call to you, Car Expert shall refund up to a maximum of 50% of the membership fee of your membership fees only.
            </ListItem>
            <ListItem>
              All cancellations as approved by the Car Expert's management, as per their discretion, shall ordinarily be processed within 30 days of receipt by Car Expert Membership's Welcome Pack. In case of no contact post approval, the 30 days may get extended.
            </ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Important Refund Notes:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>
              In case no contact is established with the member(s) by Car Expert’s central retention team for membership cancellation request, the cancellation process will be put on hold till further contact can be established.
            </ListItem>
            <ListItem>
              Cancellation/refund will be processed only upon receipt/return of the purchased membership kit (unused) by Car Expert.
            </ListItem>
            <ListItem>
              In case there are multiple ongoing dialogues between Car Expert and the member(s), there may be few instances wherein the time-frame committed for resolving a case may take longer than committed previously.
            </ListItem>
          </UnorderedList>
        </Text>

        <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          Car Maintenance Services
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Inclusions:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Engine Oil Change</ListItem>
            <ListItem>Oil Filter Change</ListItem>
            <ListItem>Air filter change/Cleaning</ListItem>
            <ListItem>Brake Fluid Top up</ListItem>
            <ListItem>Coolant Top up</ListItem>
            <ListItem>Spark Plug Change/Cleaning</ListItem>
            <ListItem>Brake Pad cleaning</ListItem>
            <ListItem>Oil Leakage Check up</ListItem>
            <ListItem>Clutch Operation Check up</ListItem>
            <ListItem>Alternator and Pump Belt Inspection</ListItem>
            <ListItem>AC Check up</ListItem>
            <ListItem>Complete Exterior Wash and Vacuum cleaning from inside</ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Usage Process:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Call our customer care number (Monday to Friday 10:00 AM to 05:00 PM and Saturday 10:00 AM to 02:00 PM)</ListItem>
            <ListItem>Share your Registered mobile number, car number, and location.</ListItem>
            <ListItem>You will receive the confirmation call within 24 hours from our appointment fixing team.</ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Terms and Conditions:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Validity: 2 Years</ListItem>
            <ListItem>Free Service includes only labor charge, Parts/Oil cost, or any other labor work apart from service is chargeable on actual.</ListItem>
          </UnorderedList>
        </Text>

        <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} color="#000D6B" mb={4} mt={8}>
          Car Cleaning Service
        </Heading>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Inclusions:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Exterior Car Wash</ListItem>
            <ListItem>Dash Board Cleaning</ListItem>
            <ListItem>Wheel Cleaning</ListItem>
            <ListItem>Grill and Mud Flap Cleaning</ListItem>
            <ListItem>Interior Vacuuming</ListItem>
            <ListItem>Dash Board Cleaning</ListItem>
            <ListItem>Cleaning of interior fabrics</ListItem>
            <ListItem>Stain Removal Treatment</ListItem>
            <ListItem>Cleaning of hinges and side doors</ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Usage Process:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Call our customer care number</ListItem>
            <ListItem>Book an appointment</ListItem>
            <ListItem>You will receive the confirmation call within 24 hours from our appointment fixing team.</ListItem>
          </UnorderedList>
        </Text>

        <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
          Terms and Conditions:
          <UnorderedList ml={8} spacing={2}>
            <ListItem>Validity: 2 Years</ListItem>
            <ListItem>Free car cleaning includes internal & external car cleaning of your vehicle.</ListItem>
          </UnorderedList>
        </Text> */}
      </Container>
    </Box>
  );
};

export default Term;

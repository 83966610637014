import React from "react";
import {
  Box,
  Text,
  Flex,
  VStack,
  Heading,
  UnorderedList,
  ListItem,
  Divider,
  Container,
} from "@chakra-ui/react";

function Refund() {
  return (
    <Box bg="#f9f9f9" minHeight="100vh" py={10}>
      <Container maxW="container.md" bg="white" boxShadow="md" borderRadius="md" p={8}>
        <VStack spacing={6} align="start">
          <Heading as="h1" size="lg" color="#000D6B">
            Refund & Cancellation Policy
          </Heading>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
          This Refund & Cancellation Policy ("Policy") governs the cancellation of services provided by Car Expert, operated by Goinfinite Digital Services Pvt Ltd, with its corporate office at E 41 Sector 3, Noida, U.P 201301 ("Car Expert").
          </Text>

          <Heading as="h1" size="sm" color="#000D6B">
          Cancellation Policy:
          </Heading>


          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
          You may cancel your booking or service request with Car Expert up to 24 hours prior to the scheduled service.
          To initiate a cancellation, please notify Car Expert by calling or emailing the customer service team.
          </Text>

          <Heading as="h1" size="sm" color="#000D6B">
          Refund Policy:
          </Heading>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
          Refunds will be processed within 7 days of receiving a valid cancellation request, subject to the following conditions:
If the cancellation is made within the allowed time frame (24 hours prior to service), a full refund of the amount paid for the service will be issued.
Refunds will be credited back to the original payment method used at the time of booking.
          </Text>
          

        {/* <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>Discontinue the membership issued to you.</ListItem>
            <ListItem>Issue a refund of the amount paid by you as Membership Fees.</ListItem>
          </UnorderedList>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            On Cancellation as aforesaid, Car Expert shall intimate you of the fact of such Cancellation and shall inform you of the method of refund of your Membership Fees. Such Cancellation shall be subject to the following terms:
          </Text>

          <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>
              Cancellations shall be considered only if (i) a valid request is made in this regard within 14 (fourteen) days of receipt of the membership, and (ii) the entire Welcome Pack is returned to Car Expert within 14 (fourteen) days of receipt of the cancellation request as aforesaid.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have purchased the Membership under any interest-free easy monthly installment (EMI) option offered by Car Expert.
            </ListItem>
            <ListItem>
              No cancellation shall be carried out if you have in any way availed any of the benefits available under the membership prior to confirmation of cancellation by Car Expert.
            </ListItem>
          </UnorderedList>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            In the event a request for cancellation is received by Car Expert after dispatch of the Membership's Welcome Pack to you, Car Expert shall deduct an amount of Rs. 1,000 (Rupees one thousand only) or 10% of the membership fee, whichever is higher, from your membership fees and only the balance shall be refunded to you as part of the cancellation.
          </Text>

          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
            In the event a request for cancellation is received by Car Expert after a positive welcome call to you, Car Expert shall refund up to a maximum of 50% of the membership fee of your membership fees only. All cancellations as approved by the Car Expert's management as per their discretion shall ordinarily be processed within 30 days of receipt by Car Expert Membership's Welcome Pack. In case of no contact post approval, the 30 days may get extended.
          </Text>

          <Divider borderColor="gray.300" />  */}

          <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold" color="#000D6B">
            Important Note
          </Text>

          <UnorderedList spacing={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
            <ListItem>All rights to cancellation/Refund have Goinfinite Digital Services Pvt Ltd.</ListItem>
            <ListItem>No refunds will be issued for cancellations made less than 24 hours before the scheduled service.</ListItem>
            <ListItem>
            If any service benefits or discounts have been availed prior to cancellation, Car Expert reserves the right to adjust the refund amount accordingly.
            </ListItem>
            <ListItem>Car Expert reserves the right to update or modify this policy at its discretion without prior notice.</ListItem>

          </UnorderedList>
        </VStack>
      </Container>
    </Box>
  );
}

export default Refund;
